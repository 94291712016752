<template>
  <vca-card>
    <div v-if="current">
      <UsersDisplay />
    </div>
  </vca-card>
</template>
<script>
import { mapGetters } from "vuex";
import UsersDisplay from "@/components/users/view/UsersDisplay";
export default {
  name: "UsersDisplayView",
  components: { UsersDisplay },
  created() {
    if (!this.hasSystemPermission("admin")) {
      this.$router.push({ name: "NotFoundView" });
    } else {
      this.load();
    }
  },
  computed: {
    ...mapGetters({
      hasSystemPermission: "user/roles/hasSystemPermission",
      current: "users/current",
    }),
  },
  methods: {
    load() {
      this.$store
        .dispatch({
          type: "users/getById",
          data: this.$route.params.id,
        })
        .then(() => {
          const breadCrumb =
            '<a href="' +
            window.location.href +
            '" title="' +
            this.$t("users.edit.header", {
              0: this.current.full_name,
            }) +
            '">' +
            this.$t("users.edit.header", {
              0: "'" + this.current.full_name + "'",
            }) +
            "</a>";
          this.$store.commit("breadCrumb", breadCrumb);
        });
    },
  },
};
</script>
